<template>
  <CRow>
    <CCol col="12" lg="12">
      <div class="detail-div">
        <CRow>
          <div>
            <CModal :title="submitType === 'Update' ? $lang.post.crud.edit : $lang.common.general.reason" size="lg"
              :show.sync="largeModal" :no-close-on-backdrop="true" color="primary">
              <CCardBody>
                <CRow>
                  <CCol md="12">
                    <c-textarea v-model="opportunity.reason" :rows="5" :cols="10" placeholder="Add Reason"></c-textarea>
                  </CCol>
                  <CCol md="6">
                    <div class="form-group">
                      <CButton color="primary" v-c-tooltip="$lang.buttons.general.crud.submit" :disabled="submitted"
                        v-on:click="blockPost(statusId)">
                        Submit
                      </CButton>
                    </div>
                  </CCol>
                </CRow>
              </CCardBody>
              <template #footer style="display: none">
                <CButton @click="darkModal = false" color="danger" style="display: none">Discard</CButton>
                <CButton @click="darkModal = false" color="success" style="display: none">Accept</CButton>
              </template>
            </CModal>
          </div>
        </CRow>
        <CRow>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">
              {{ $lang.opportunity.form.user }}
            </p>
            <p class="custome-detail">
              <ProfilerDetail :username="opportunity.userWithCompany" :profilerId="opportunity.userId"></ProfilerDetail>
            </p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="opportunity.branchInfo" v-if="opportunity.whose === 'Company'">
            <p class="custome-labal">{{ $lang.post.form.branch }}</p>
            <BranchComponent :data="opportunity.branchInfo"></BranchComponent>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="opportunity.ratingLabel">
            <p class="custome-labal">{{ $lang.opportunity.form.information }}</p>
            <p class="custome-detail">{{ opportunity.ratingLabel }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.opportunity.form.date }}</p>
            <p class="custome-detail">{{ getDateWithFormat(opportunity.date) }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.opportunity.form.opportunityType }}</p>
            <p class="custome-detail">{{ opportunity.opportunityType }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.opportunity.form.name }}</p>
            <p class="custome-detail">{{ opportunity.name }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.opportunity.form.industry }}</p>
            <p class="custome-detail">{{ opportunity.industry.label }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="opportunity.subIndustry">
            <p class="custome-labal">{{ $lang.opportunity.form.subIndustry }}</p>
            <p class="custome-detail">{{ opportunity.subIndustry }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.post.form.visibility }}</p>
            <p class="custome-detail">
              {{
                opportunity.visibility === 1 ? "Local" : (opportunity.visibility === 2 ? "National" :
                  (opportunity.visibility === 3 ? "International" : ""))
              }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-if="opportunity.whose === 'Company'">
            <p class="custome-labal">{{ $lang.post.form.individualshowToOther }}</p>
            <p class="custome-detail">{{ opportunity.showToOther === 1 ? 'Yes' : 'No' }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-if="opportunity.whose === 'Individual'">
            <p class="custome-labal">{{ $lang.post.form.companyshowToOther }}</p>
            <p class="custome-detail">{{ opportunity.showToOther === 1 ? 'Yes' : 'No' }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="opportunity.duration">
            <p class="custome-labal">{{ $lang.opportunity.form.duration }}</p>
            <p class="custome-detail">{{ opportunity.duration }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="opportunity.currency">
            <p class="custome-labal">{{ $lang.opportunity.form.currency }}</p>
            <p class="custome-detail">{{ opportunity.currency.title }} ({{ opportunity.currency.symbol }})</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="opportunity.min_budget">
            <p class="custome-labal">{{ $lang.opportunity.form.budget }}</p>
            <p class="custome-detail">{{ opportunity.symbol }}&nbsp;{{ opportunity.min_budget }} -
              {{ opportunity.max_budget }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="opportunity.skills">
            <p class="custome-labal">{{ $lang.opportunity.form.skills }}</p>
            <p class="custome-detail">
              {{ opportunity.skills }}
            </p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="opportunity.remoteWork">
            <p class="custome-labal">{{ $lang.opportunity.form.isRemoteWork }}</p>
            <p class="custome-detail">{{ opportunity.remoteWork }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4" v-show="opportunity.remoteWorkAddress">
            <p class="custome-labal">
              {{ $lang.opportunity.form.remoteWorkAddress }}
            </p>
            <p class="custome-detail">{{ opportunity.remoteWorkAddress }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">{{ $lang.opportunity.form.status }}</p>
            <p class="custome-detail">
              <CBadge :color="statusBudget(opportunity.isStatus)">{{ opportunity.isStatus }}</CBadge>
            </p>
          </CCol>
          <CCol sm="12" md="12" lg="12">
            <p class="custome-labal">{{ $lang.opportunity.form.description }}</p>
            <Description :title="$lang.opportunity.form.description" :content="opportunity.description" :length="90" />
          </CCol>
        </CRow>
        <EntityTimeStamp :createdAt="opportunity.createdAt" :updatedAt="opportunity.updatedAt"></EntityTimeStamp>
        <CRow v-show="opportunity.blockedReason">
          <CCol sm="12" align="left">
            <strong>Blocked Reason: </strong> {{ opportunity.blockedReason }}
          </CCol>
        </CRow>
        <CRow v-if="!opportunity.blockedReason && opportunity.isStatus != 'Closed'">
            <CCol sm="12" align="left">
              <CButton :color="statusColor" v-c-tooltip="statusLabel" v-on:click="beforeBlocked()">
                {{ statusLabel }}
              </CButton>
            </CCol>
        </CRow>
      </div>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import BranchComponent from "/src/views/branch/Component";
import {
  opportunity,
  industry,
  duration,
  currencytype,
  users,
  skills,
} from "/src/store/url.js";
import { Mixin } from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import VueToasted from "vue-toasted";
import moment from "moment";
import Description from "/src/views/Description";
import ProfilerDetail from "/src/views/ProfilerDetail.vue";
import { serverBus } from "/src/main";
import EntityTimeStamp from "/src/components/EntityTimeStamp";

function toast({ title, message, type, timeout, cb }) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueToasted);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);

export default {
  name: "OpportunityEdit",
  mixins: [Mixin],
  components: {
    Description,
    ProfilerDetail,
    EntityTimeStamp,
    BranchComponent
  },
  data() {
    return {
      submitType: "",
      userId: "",
      largeModal: false,
      statusId: "",
      statusLabel: "Block",
      statusColor: "danger",
      submitted: false,
      showTax: true,
      err_msg: "",
      dismissCountDown: 0,
      postTypeList: [],
      statusOption: [],
      industryOptions: [],
      subIndustryOptions: [],
      resourceTypeOptions: [],
      currencyOptions: [],
      durationOptions: [],
      remoteWorkOptions: [],
      userOptions: [],
      skillsOptions: [],
      selectedSkills: [],
      getSkills: [],
      self: this,
      module: opportunity,
      moduleIndustry: industry,
      moduleDuration: duration,
      moduleCurrency: currencytype,
      moduleSkills: skills,
      moduleUser: users,
      opportunity: {
        name: "",
        branchInfo: "",
        description: "",
        remoteWork: "",
        subIndustry: "",
        currency: "",
        duration: "",
        industry: "",
        isActive: 1,
        budget: "",
        user: "",
        skills: "",
        status: "",
        ratingLabel: "",
        company: "",
        isStatus: "",
        whose: "",
        reason: "",
        symbol: "",
        userWithCompany: "",
        createdAt: '',
        updatedAt: '',
        blockedReason: '',
        min_budget: '',
        max_budget: '',
        opportunityType: '',
        date: '',
      },
    };
  },

  mounted() {
    store.commit("showLoader", true);
    const type = {
      YES: "Yes",
      NO: "No",
    };
    let self = this;
    self.statusOption.push(
      { value: "1", label: "Active" },
      { value: "0", label: "InActive" },
      { value: "2", label: "Block" },
      { value: "3", label: "Closed" },
      { value: "4", label: "Draft" }
    );
    self.dismissCountDown = 0;
    const id = this.$route.params.id;

    self.remoteWorkOptions.push(
      { value: type.YES, label: type.YES },
      { value: type.NO, label: type.NO }
    );
    self.dismissCountDown = 0;
    self.editId = id;
    axios
      .get(this.viewUrlApi(this.module, id))
      .then((response) => {
        if (response.data) {
          const responseData = response.data.data;
          self.opportunity.date = responseData.startDate; //  moment(responseData.startDate, 'YYYY-MM-DD').format('DD/MM/YYYY');
          self.opportunity.name = (responseData.name !== "") ? responseData.name : '-';
          self.opportunity.ratingLabel = responseData.ratingLabel;
          self.opportunity.description = (responseData.description !== "") ? responseData.description : '-';
          self.opportunity.budget = responseData.budget ?? '-';
          self.opportunity.isActive = responseData.isActive;
          self.opportunity.isStatus = responseData.isActiveStatus;
          self.opportunity.visibility = responseData.visibility;
          self.opportunity.showToOther = responseData.showToOther;
          self.opportunity.branchInfo = responseData.branchInfo;
          self.opportunity.blockedReason = responseData.blockedReason;

          if (self.opportunity.isStatus === "Active") {
            self.opportunity.isStatus = 'Open';
            // console.log(this.getStatusLabel(2));
            self.statusLabel = this.getStatusLabel(2);
            self.statusId = "2";
            self.statusColor = "danger";
          } else {
            self.statusId = "1";
            self.statusLabel = this.getStatusLabel(1);
            self.statusColor = "success";
          }

          self.opportunity.remoteWork = responseData.remoteWork;
          self.opportunity.max_budget = responseData.maxBudget;
          self.opportunity.min_budget = responseData.minBudget;
          self.opportunity.opportunityType = responseData.opportunityType;
          self.opportunity.remoteWorkAddress = responseData.remoteWorkAddress;
          self.opportunity.isGST = responseData.isGST ?? '-';
          self.opportunity.tax = responseData.GstTax;
          if (responseData.isGST === "No") {
            self.showTax = false;
          }
          self.opportunity.status = {
            value: responseData.isActive,
            label: responseData.isActiveStatus,
          };

          self.opportunity.industry = responseData.industry ? {
            value: responseData.industry.id,
            label: responseData.industry.title,
          } : '';

          const servicesList = [];
          responseData.subindustry.map(function (value, key) {
            servicesList.push(value.title);
          });
          self.opportunity.subIndustry = servicesList.join(", ");

          self.opportunity.duration = responseData.duration ? responseData.duration.value : '-';
          self.opportunity.currency = responseData.currency ?? '';
          self.opportunity.symbol = responseData.currency ? responseData.currency.symbol : '';

          self.opportunity.whose = responseData.user.whose;
          if (responseData.user.company)
            self.opportunity.user = responseData.user.company;
          else if (responseData.user.name)
            self.opportunity.user = responseData.user.name;

          self.opportunity.userWithCompany = responseData.user.userWithCompany;
          self.opportunity.userId = responseData.userId;
          self.opportunity.createdAt = responseData.createdAt;
          self.opportunity.updatedAt = responseData.updatedAt;

          const list = [];
          responseData.skills.map(function (value, key) {
            list.push(value.title);
          });
          self.opportunity.skills = list.join(", ");

          store.commit("showLoader", false); // Loader Off
        }
      })
      .catch(function (error) {
        store.commit("showLoader", false); // Loader Off
      });
  },
  methods: {
    showProfile(userId) {
      serverBus.$emit("profilerId", userId);
    },

    getHumanDate: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },

    getStatusLabel(getStatus) {
      let valObj = " ";
      valObj = this.statusOption.filter(function (elem) {
        if (elem.value == getStatus) return elem.value;
      });
      if (valObj.length > 0) return valObj[0].label;
    },

    beforeBlocked() {
      let self = this;
      self.opportunity.reason = "";
      // console.log(self.opportunity);
      if (self.opportunity.isActive === 1) {
        this.largeModal = true;
      } else {
        this.blockPost(self.statusId);
      }
    },

    blockPost(statusId) {
      let self = this;
      self.submitted = true; //Enable Button
      if (self.opportunity.isActive === 1) {
        if (self.opportunity.reason === "") {
          self.submitted = false; //Enable Button
          return false;
        }
        this.$swal({
          title: this.$lang.common.status.want_to_block,
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#e55353",
          confirmButtonText: this.$lang.common.status.yes_block,
          cancelButtonText: this.$lang.common.status.cancel,
        }).then((result) => {
          if (result.value) {
            const id = this.$route.params.id;

            setTimeout(() => {
              let self = this;
              const postData = {
                status: statusId,
                reason: self.opportunity.reason,
              };
              let status, button;
              if (self.statusId === "1") {
                self.statusId = "2";
                self.statusColor = "danger";
                status = this.getStatusLabel(1);
                button = "Block";
              } else {
                self.statusId = "1";
                self.statusColor = "success";
                status = "Blocked";
                button = this.getStatusLabel(1);
              }

              axios
                .post(this.statusUrlWeb(this.module, id), postData)
                .then(function (response) {
                  if (response.data.code === 200) {
                    self.opportunity.isStatus = status;
                    self.statusLabel = button;
                    self.largeModal = false;
                    self.submitted = false; //Enable Button
                    self.$router.go();
                  } else {
                    self.err_msg = response.data.message;
                    self.dismissCountDown = 10;
                  }
                })
                .catch(function (error) {
                  self.submitted = false; //Enable Button
                });
            }, 500);
          }
        });
      } else {
        const id = this.$route.params.id;
        let self = this;
        const postData = {
          status: statusId,
          reason: "",
        };
        let status, button;
        if (self.statusId === "1") {
          self.statusId = "2";
          self.statusColor = "danger";
          status = this.getStatusLabel(1);
          button = "Block";
        } else {
          self.statusId = "1";
          self.statusColor = "success";
          status = "Blocked";
          button = this.getStatusLabel(1);
        }
        axios
          .post(this.statusUrlWeb(this.module, id), postData)
          .then(function (response) {
            if (response.data.code === 200) {
              self.opportunity.isStatus = status;
              self.statusLabel = button;
              self.largeModal = false;
              self.submitted = false; //Enable Button
              self.$router.go();
            } else {
              self.err_msg = response.data.message;
              self.dismissCountDown = 10;
            }
          })
          .catch(function (error) {
            self.submitted = false; //Enable Button
          });
      }
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
